@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/*
------------------------------------------------------------
Project Name : metrixData360;
------------------------------------------------------------
*/

/* Reset
--------------------------------------------------------------------------------------------------------*/

/*
http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

.strong,
strong {
  font-weight: 700;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* General
--------------------------------------------------------------------------------------------------------*/
* {
  outline: none !important;
  box-sizing: border-box;
}

a,
.main-navigation {
  transition: all 0.3s;
}

html,
body {
  min-width: 320px;
  min-height: 100vh;
}

body {
  font-size: 16px;
  line-height: 22px;
  color: #4f4f4f;
  background-color: #f4f4f4;
  font-family: 'Roboto', sans-serif;
  -ms-overflow-style: scrollbar;
  -webkit-font-smoothing: subpixel-antialiased;
}

.wrapper {
  min-height: 100vh;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #999;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2em;
  color: #4f4f4f;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
  display: block;
}

h1 {}

h2 {}

h3 {}

h4 {
  font-size: 22px;
  line-height: 26px;
  font-weight: 500;
}

h5 {}

h6 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}

p {
  padding: 0 0 20px;
}

input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  -webkit-appearance: none;
  appearance: none;
}

::-webkit-input-placeholder {
  color: #000;
}

::-moz-placeholder {
  color: #000;
}

:-ms-input-placeholder {
  color: #000;
}

:-moz-placeholder {
  color: #000;
}

::-moz-focus-inner {
  border: 0;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

option:not(:checked) {
  color: black;
}

em,
a {
  display: inline-block;
}

img {
  max-width: 100%;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.f-wrap {
  flex-wrap: wrap;
}

.mr-1 {
  margin-right: 10px !important;
}

.ant-form {
  color: #4f4f4f;
}

.title-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
  gap: 8px;

  .right-title .ant-select {
    width: 182px;
    max-width: 100%;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  .right-title .ant-form {
    .ant-form-item {
      margin-right: 10px;
    }
  }
}

.bulk-import-table {
  width: 100%;
}

.main-card {
  padding: 18px 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

label {
  font-size: 14px;
  line-height: 18px;
}

.label {
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 6px;
}

.btns-block {
  display: flex;

  >* {
    margin-right: 10px;

    &:last-child {
      margin-right: 0px;
    }
  }
}

.tabel-search {
  .ant-form-inline {
    .ant-form-item {
      margin-bottom: 10px;
    }
  }

  .btns-block {
    flex-wrap: wrap;

    >* {
      margin-bottom: 10px;
    }
  }
}

.input-btns-title {
  padding-bottom: 28px;
  margin-bottom: 30px;
  border-bottom: 1px solid #dddddd;

  .ant-row {
    align-items: flex-end;
  }

  &.no-border {
    border: none;
    margin-bottom: 15px;
    padding-bottom: 0;
  }
}

.w-100 {
  width: 100%;
}

.sm-input {
  width: 182px;
  max-width: 100%;
}

.form-group {
  margin-bottom: 18px;
}

.form-inline {
  display: flex;
  align-items: center;

  .label {
    min-width: 122px;
    max-width: 122px;
    padding: 0 8px 0 0;
    margin: 0;
    word-break: break-all;
  }

  .ant-row {
    width: calc(100% - 122px);
  }
}

.form-inline-pt {
  padding-top: 24px;
  display: flex;

  .ant-form-item-control-input {
    min-height: auto;
  }

  .label {
    margin: 2px 0px 0 10px;
    width: calc(100% - 40px);
  }
}

.filter-input {
  min-width: 200px;
  width: 100%;

  &.lg {
    min-width: 208px;
  }

  &.sm {
    min-width: 120px;
  }
}

.ovarlap {
  visibility: hidden;
  opacity: 0;
}

.filter-btn.ant-btn {
  padding: 0 8px;
  background: #e3e2e2;
  border-color: #dddddd;
  color: #4f4f4f;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.active {
    background: #014e97;
    border-color: #014e97;

    img {
      visibility: hidden;
      opacity: 0;
    }

    .ovarlap {
      visibility: visible;
      opacity: 1;
    }
  }
}

.spin-loader {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-filter-group {
  display: flex;
}

.input-filter-group .ant-select .ant-select-selector,
.input-filter-group .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-filter-group>.filter-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-file-path {
  display: inline-flex;
  align-items: center;

  .file-name {
    padding: 0 6px 0 0;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    line-height: 18px;
  }

  .ant-btn {
    background-color: #e9e9e9;
  }
}

.upload-file>span {
  display: inline-flex;
  align-items: center;
  width: 100%;

  .ant-upload-list.ant-upload-list-text {
    padding-left: 8px;
    width: calc(100% - 140px);
  }

  .ant-upload-list-item {
    margin: 0;
  }

  .ant-upload-list-item-card-actions-btn.ant-btn-sm {
    width: 30px;
    height: 30px;
    min-width: 30px;
    background-color: #e9e9e9;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
  }

  .ant-upload-list-item-name {
    max-width: max-content;
    color: #4f4f4f;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    line-height: 18px;
  }

  .ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: transparent;
  }
}

.custom-upload-file-wrapper {
  .custom-upload-file {
    position: relative;
    margin-bottom: 4px;

    >input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;

      &:hover+.layout-upload {
        border-color: #014e97;
      }
    }

    .layout-upload {
      padding: 68px 0;
      margin: 4px;
      height: 165px;
      border: 1px dashed #d9d9d9;
      text-align: center;
    }

  }
}

.ant-upload.ant-upload-drag.py-sm .ant-upload {
  padding: 6px 0;
}

.update-excel-page .select-table {
  margin-bottom: 0;
  width: 300px;
  max-width: 100%;
}

.d-flex {
  display: flex;
}

.align-item-start {
  align-items: flex-start !important;
}

.form-label-hide .ant-form-item-label>label {
  display: none;
}

.graph-heading {
  font-size: medium;
  font-weight: 600;
}

// ant-design
.btn-icon {
  display: inline-flex;
  align-items: center;

  img {
    min-width: 20px;
  }
}

.ant-btn {
  background: #ececec;
  border-color: #ececec;
  color: #4f4f4f;
}

.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  color: inherit;
  background: #d2d2d2;
  border-color: #d2d2d2;
}

.ant-btn-primary {
  background: #014e97;
  border-color: #014e97;
  color: #fff;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active {
  color: #fff;
  background: #001529;
  border-color: #001529;
}

.ant-btn::after {
  display: none;
}

textarea.ant-input {
  max-height: 32px;
}

.form-control {
  border: 1px solid #dddddd;
  box-shadow: none !important;
}

.form-control:hover,
.form-control:focus,
.form-control:active {
  border-color: #00274d !important;
}

.ant-input::placeholder {
  color: #999999;
}

.ant-input-prefix {
  margin-right: 8px;
}

.ant-select {
  color: #4f4f4f;
  width: 100%;
}

.ant-select-selection-placeholder {
  color: #999999;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #dddddd;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #00274d;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #00274d;
  box-shadow: none;
}

// select pop
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #fff;
  font-weight: 500;
  background-color: #014e97;
}

.ant-select-item .ant-select-item-option-state .anticon {
  color: #ffffff;
}

.filter-dropdown-pop {
  width: 250px !important;
}

.value-box-select {
  width: 400px !important;
}

.value-box-select-medium {
  width: 180px !important;
}

.icon-box-select {
  background-color: #014e97;
}

.ant-picker {
  color: #4f4f4f;
  border: 1px solid #dddddd;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: #014e97;
  box-shadow: none;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #014e97;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #014e97;
}

.ant-picker-today-btn,
.ant-picker-header-view button {
  color: #014e97;
}

.ant-picker-today-btn:hover,
.ant-picker-header-view button:hover {
  color: #001529;
}

.ant-picker-range .ant-picker-active-bar {
  background: #014e97;
}

.filter-input .ant-picker.ant-picker-range {
  .ant-picker-input {
    max-width: 73px;
  }

  .ant-picker-range-separator {
    padding: 0px 4px 0 0;
  }
}

.anticon {
  color: #4f4f4f;
}

.deleteMap-btn {
  width: 15px;
  height: 15px;
  min-width: 15px;
  background-color: transparent;
  border-radius: 50%;
  margin-left: 20px;
}

.deleteMap-btn img {
  max-width: 18px;
  display: block;
}

.action-btn {
  width: 30px;
  height: 30px;
  min-width: 30px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-btn img {
  max-width: 18px;
  display: block;
}

.action-btn:hover,
.action-btn:active,
.action-btn:focus {
  background-color: #d2d2d2;
}

.custom-table .ant-table {
  color: #4f4f4f;

  .btns-block .action-btn {
    margin-right: 4px;

    &:last-child {
      margin-right: 0px;
    }
  }
}

.custom-table .ant-table-thead>tr>th {
  color: #4f4f4f;
  background: #f6f6f6;
  border: none;

  &::before {
    display: none;
  }
}

.custom-table.first-row-header {
  .ant-table-tbody {
    tr.ant-table-row:nth-child(2) {
      td {
        background-color: #f6f6f6;
        color: #4f4f4f;
      }
    }
  }
}

.ant-table-tbody>tr>td {
  border-bottom: 1px solid #e1e1e1;
}

.custom-table .ant-table-thead>tr>th,
.custom-table .ant-table-tbody>tr>td,
.custom-table .ant-table tfoot>tr>th,
.custom-table .ant-table tfoot>tr>td {
  padding: 8px;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #1890ff;
  opacity: 0.8;
}

.ant-table-pagination {
  .ant-pagination-total-text {
    margin-right: auto;
  }
}

.ant-pagination-item a {
  color: #4f4f4f;
}

.ant-pagination-item:focus-visible,
.ant-pagination-item:hover {
  border-color: #d9d9d9;
  background-color: #ececec;
}

.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
  color: #4f4f4f;
}

.ant-select-tree-node-selected:not(.ant-select-tree-node-disabled) {
  color: #fff;
  font-weight: 500;
  background-color: #014e97 !important;
}

// checkbox
.ant-checkbox-wrapper {
  color: #4f4f4f;
  font-size: 16px;
  line-height: 20px;
}

.ant-checkbox {
  color: #4f4f4f;
}

.ant-checkbox-inner {
  border: 1px solid #ddd;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #014e97;
  border-color: #014e97;
}

.ant-checkbox-checked::after {
  border: 1px solid #014e97;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #014e97;
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
  border-color: #014e97;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #014e97;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #fff;
}

.ant-switch {
  min-width: 40px;
  height: 18px;
  line-height: 22px;
}

.ant-switch-handle {
  top: 1px;
  width: 14px;
  height: 14px;
}

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 14px - 2px);
}

.ant-switch-checked {
  background-color: #014e97;
}

// dropdown ---------------------------
.profile-dropdown {
  .ant-dropdown-menu {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    border-radius: 0px 0px 4px 4px;
    color: #4f4f4f;
  }
}

// custom dropdown ---------------------------
.custom-popover {
  padding: 0;
  font-size: 16px;
  line-height: 20px;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    width: 225px;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }

  .ant-popover-inner-content {
    padding: 15px 22px;
    color: #4f4f4f;
  }

  .checkbox-list-wrapper {
    position: relative;

    .checkbox-list {
      margin: 0 -22px 10px;
      max-height: 376px;
      overflow-y: auto;

      li {
        padding: 0px 8px 0px 22px;
      }

      .line-bottom {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: -8px;
          width: 100%;
          height: 1px;
          background-color: #dddddd;
        }
      }

      li+li {
        margin-top: 15px;
      }
    }
  }
}

// modal -----------------------
.custom-modal {
  .ant-modal {
    width: 800px !important;
    max-width: 100%;
    padding: 15px;

    .ant-modal-footer {
      text-align: left;
    }
  }

  .ant-modal-header {
    padding: 16px 20px;
  }

  .ant-modal-title {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
  }

  .ant-modal-body {
    padding: 16px 20px;
  }

  .modal-footer {
    padding: 20px 0px 10px;
  }

  .modal-footer.pt-lg {
    padding: 40px 0px 10px;
  }
}

// hamburger---------------------------------
.hamburger {
  display: inline-block;
  padding: 10px;
}

.hamburger .line {
  width: 24px;
  height: 2px;
  background-color: #4f4f4f;
  display: block;
  margin: 0 auto 4px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger .line:nth-child(3) {
  margin: 0;
}

.hamburger:hover {
  cursor: pointer;
}

.hamburger.is-active .line:nth-child(2) {
  opacity: 0;
}

.hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(6px) rotate(45deg);
  -ms-transform: translateY(6px) rotate(45deg);
  -o-transform: translateY(6px) rotate(45deg);
  transform: translateY(6px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-6px) rotate(-45deg);
  -ms-transform: translateY(-6px) rotate(-45deg);
  -o-transform: translateY(-6px) rotate(-45deg);
  transform: translateY(-6px) rotate(-45deg);
}

// react-custom-scrollbars
.track-vartical {
  border-radius: 30px;
  background-color: #dddddd;
  cursor: pointer;
  width: 15px !important;
}

/* Header
--------------------------------------------------------------------------------------------------------*/
.header {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background-color: #fff;
  z-index: 10;

  .left-header {
    width: 220px;
    background-color: #00274d;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .right-header {
    padding-left: 8px;
    width: calc(100% - 220px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dddddd;

    .right-list {
      display: flex;
      align-items: center;
    }

    .right-list>*:not(:last-child) {
      margin-right: 4px;
    }
  }

  .logo {
    display: inline-block;
    max-width: 136px;
  }

  .logo>img {
    display: block;
  }
}

.profile-block {
  display: inline-flex;
  align-items: center;
  background-image: url(../images/ic-down.svg);
  background-position: right 18px center;
  background-repeat: no-repeat;
  background-size: 15px;
  padding: 9px 43px 10px 10px;
  color: #4f4f4f;
  background-color: transparent;

  &:hover,
  &.ant-dropdown-open {
    background-color: #f4f4f4;
  }

  .dp {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 40px;
      min-width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }

  .username {
    display: inline-block;
    padding-left: 8px;
  }
}

/* MainNavigation
--------------------------------------------------------------------------------------------------------*/
.main-navigation {
  width: 220px;
  position: fixed;
  z-index: 10;
  top: 60px;
  left: 0;
  background-color: #00274d;
  height: calc(100vh - 60px);
  overflow-y: auto;
  overflow-x: hidden;

  .ant-menu {
    background-color: #00274d;
    color: #fff;
    border: none;
    font-size: 16px;
    line-height: 20px;
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    opacity: 0.6;
    margin: 0 !important;

    >.ant-menu-item-icon {
      max-width: 20px;
    }

    &:hover {
      opacity: 1;
      color: #fff;
    }

    &:active {
      background-color: #014e97;
    }
  }

  .ant-menu-item-selected {
    color: #fff;
    opacity: 1;
    width: 100%;
  }

  .ant-menu-submenu-selected {
    color: #fff;
  }

  .ant-menu-title-content a {
    color: #fff;
    display: block;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #014e97;
  }

  .ant-menu-inline .ant-menu-item::after {
    display: none;
  }

  .ant-menu-submenu.ant-menu-submenu-open {
    background-color: #001529;

    .ant-menu {
      background-color: #001529;
      margin-top: 8px;
    }
  }

  .ant-menu-submenu .ant-menu {
    margin-top: 8px;

    .ant-menu-item,
    .ant-menu-submenu-title {
      margin-bottom: 3px !important;
    }
  }

  .ant-menu-submenu-expand-icon,
  .ant-menu-submenu-arrow {
    color: rgba(255, 255, 255, 0.6);
  }

  .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon,
  .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
    color: #fff;
  }

  .no-icon .ant-menu-sub .ant-menu-title-content {
    margin-left: 6px;
  }
}

/* Main Content
--------------------------------------------------------------------------------------------------------*/
.wrapper {
  padding: 60px 0 0 220px;
  transition: all 0.3s;
}

.main-content {
  padding: 13px 28px;
  position: relative;
}

.main-content::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.show-menu .main-content::before {
  opacity: 1;
  visibility: visible;
}

//Show Version in Side Bar

.sidebar-main {
  display: flex;
  flex-direction: column;
  height: 100%;

  .sidebar-version {
    margin-top: auto;
    padding-bottom: 10px;
    color: #fff;
    font-size: 14px;
    text-align: center;

    span {
      cursor: pointer;
    }

    hr {
      width: 80%;
      border-width: 1px;
    }
  }
}

// toggle, show menu-----------
.toggle-menu .main-navigation {
  left: -220px;
}

.toggle-menu .wrapper {
  padding: 60px 0 0 0px;
}

.show-menu {
  overflow: hidden;
}

.show-menu .main-navigation {
  left: 0;
}

/* Footer
--------------------------------------------------------------------------------------------------------*/
.footer {
  background: #333;
  padding: 15px;
  text-align: center;
}

.copyright {
  padding: 0;
  color: #fff;
  font-size: 0.8em;
  line-height: 1.2em;
}

/* Responsive Media Queries
--------------------------------------------------------------------------------------------------------*/
@media (max-width: 1199px) {
  .lg-none {
    display: none;
  }
}

@media (max-width: 991px) {
  .main-navigation {
    left: -220px;
  }

  .wrapper {
    padding: 60px 0 0 0px;
  }

  .md-none {
    display: none;
  }
}

@media (max-width: 767px) {
  .sm-none {
    display: none;
  }

  .main-content {
    padding: 13px 16px;
  }

  .main-card {
    padding: 16px;
  }

  .ant-table table {
    white-space: nowrap;
  }

  .title-block .right-title {
    width: 100%;
    margin: 6px 0 0;
  }

  .title-block .right-title .ant-select {
    width: 32%;
    margin: 0 10px 10px 0;
  }

  .title-block .right-title .ant-form {
    .ant-form-item {
      width: 32%;

      .ant-select {
        width: 100%;
        margin: 0;
      }
    }
  }

  .ant-table-pagination.ant-pagination {
    justify-content: center;
  }

  .ant-table-pagination .ant-pagination-total-text {
    margin-right: 0;
    width: 100%;
    text-align: center;
  }

  .update-excel-page .select-table {
    margin: 0 0 10px;
    width: 100%;

    .ant-form-item-label {
      padding: 0 !important;

      >label::after {
        display: inline-block;
      }
    }
  }
}

@media (max-width: 575px) {
  .header .left-header {
    width: 120px;
  }

  .header .right-header {
    padding-left: 0;
    width: calc(100% - 120px);
  }

  .header .logo {
    max-width: 97px;
  }

  .ant-btn {
    padding: 4px 8px;
  }

  .profile-block {
    background-image: none;
    padding: 9px;
  }

  .profile-block .username {
    display: none;
  }

  .search-block .btns-block {
    width: 100%;
    margin: 6px 0 0;
    flex-wrap: wrap;

    >* {
      margin: 0 8px 6px 0;
    }
  }

  .title-block .right-title .ant-select {
    width: 100%;
    margin: 0 0px 10px 0;

    &:last-child {
      margin: 0;
    }
  }

  .title-block .right-title .ant-form {
    .ant-form-item {
      width: 100%;
      margin: 0 0px 10px 0;
    }
  }

  .form-inline-pt {
    padding-top: 0px;
  }

  .custom-modal .modal-footer.pt-lg {
    padding: 20px 0px 10px;
  }

  .upload-file-path .file-name {
    padding: 0 4px;
    max-width: 200px;
  }
}

.report-style-class {
  height: 100%;
  width: 100%;
}

.value-badge {
  background-color: #2a81ac;
  color: #fff;
  font-size: 12px;
  border-radius: 3px;
  font-weight: 400;
  padding: 4px 8px;
  text-align: center;
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 4px;
  line-height: normal;
  text-transform: uppercase;
  min-width: 60px;
}

.custom-tooltip {
  max-width: 650px;
}

.auto-cursor:hover {
  cursor: auto;
}