
.container-version li {
    list-style-type: disc;
}

.container-version ul {
    margin-left: 25px;
    margin-bottom: 10px;
}

.container-version a {
    color: blue;
    font-style: italic;
}